
.thanksPageMainDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:2rem;
 height: 100vh;
 width: 93.5vw;
padding-top: 1rem;
}
.thanksDiv{
font-size: 50px;
font-weight: 700;

}
.tickImg{
    height: 10rem;
    width: 15rem;

}
.thankStatementDiv{
    font-size: 30px;
}
