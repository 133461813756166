.errorMainDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:2rem;
    padding-top: 5rem;
    height: 100vh;
    background-color: #f6f6f6;
}
.errorImg{

    height: 15rem;
}
.errorStatement{
    font-size: 30px;

}