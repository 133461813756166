@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
*{font-family: 'Roboto', sans-serif;
    background: '#F8F8F8';
    scrollbar-width: thin;
}

html {
    font-size: 62.5%;
}
.headerLogo {
     height: calc(6.5vh -  2vh); 
    }
    
    .header {
        height:6.5vh;
        background-color: white;
        padding: 0% 1% 0% 1%;
        border: 1px solid rgb(213, 211, 211);
    }
@media only screen and (min-width: 1200px) {

    html {
        font-size: 62.5%;
    }
}

@media only screen and (min-width: 1400px) {

    html {
        font-size: 70%;
    }
}

@media only screen and (min-width: 1700px) {

    html {
        font-size: 80%;
    }
}

@media only screen and (min-width: 2000px) {

    html {
        font-size: 95%;
    }
}

@media only screen and (min-width: 1200px) {
    .mainContainer{
 display: grid;
    height: 100vh;
    padding-bottom: 4vh;

grid-template-areas: "heading heading heading heading heading heading heading"
                    "ticketDetail ticketDetail   ticketDetail calendar calendar calendar timer  "
                    "ticketDetail ticketDetail   ticketDetail calendar calendar calendar timer  "
                    "ticketDetail ticketDetail   ticketDetail calendar calendar calendar timer  "
                    "ticketDetail ticketDetail   ticketDetail calendar calendar calendar timer  "
                    "ticketDetail ticketDetail   ticketDetail calendar calendar calendar timer  "
                     "feDetails feDetails  feDetails calendar calendar calendar timer "
                      "clusterLead clusterLead  clusterLead calendar calendar calendar timer "
                   }

.timerWhiteDiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 75vh;
    width: 18vw;
    padding-left: 2rem;
    padding-top: 1rem;
}

.calendarWhiteDiv{
    height: 75vh;
}

.mainContainer {
    height: 100vh;
    position: absolute;
  background-color:'#F8F8F8';
  padding-left: 7vh;
  padding-right: 7vh;
  
}
.calendarMonth{
    height: 15vh;
}
.tableRow{
    width: 3vh;
   height: 1vh;
}
body ::-webkit-scrollbar-track {
    background: #fff;
    cursor: pointer !important;
    position: sticky !important;
    top: 0 !important;
}
body ::-webkit-scrollbar-thumb {
    background: #999999;
    cursor: pointer !important;
}
.tableMain{
    margin-left: 2rem;
}
.ticketDetailCard{
    width: 30vw;
}
.feDetail{
    width: 30vw;
}
.clusterLead{
width: 30vw;
      }
.appointmentCalendar{
      width: 45vw;
      padding-top: 1rem;
 }
 .appointmentTimer{
    padding-top: 1rem;
 }
}
.ticketDetailCard{
grid-area: ticketDetail;
 }
 .appointmentCalendar{
 grid-area: calendar;
        }
 .appointmentTimer{
     grid-area: timer;
        }
 .feDetail{
       grid-area: feDetails;
 }
 .clusterLead{
      grid-area:clusterLead;
        }
.heading{
        grid-area: heading;
    }


 .timerWhiteDiv{
    grid-area:  timerWhiteDiv;
 }   

 